import { useHistory } from 'react-router-dom'
import Top from '../component/Top'
import { Input, message } from "antd";
import { useTranslation } from "react-i18next";

import { useEffect, useRef, useState } from "react";
import ExchangeModal from "../component/ExchangeModal";
import RewardsModal from "../component/Rewards";
import RecordTab from "../component/RecordTab";
import { verifyToken, walletExchange, walletWithdraw, indexIndex,usersRegular,usersAccept,usersAcceptClaim } from '../api/httpApiList';
// import Loding from "../component/Loading";
import Regular1Modal1 from '../component/Regular1Modal1';
import { showGlobalLoading, hideGlobalLoading } from "../component/Loading";
import {
    getAccount,
    getNetwork
} from "@wagmi/core";
export default () => {
    const history = useHistory();
    const { t } = useTranslation();

    const myInput = useRef()
    const [activeTabId, setActiveTabId] = useState("Exchange");
    // 首页数据
    const [homeData, setHomeData] = useState();
    const [exchangeModal, setExchangeModal] = useState(false)
    const [rewordModal, setRewordModal] = useState(false);
    const [exchangeAmount, setExchangeAmount] = useState("");
    //提现金额
    const [withdrawAmount, setWithdrawAmount] = useState("");
    const redirectHandle = () => {
        const account = getAccount();
        if (account.isConnected) {
            //账户链接中
            history.replace("/poolData")
        } else {
            message.warning(t("请先领取凭证"));
        }
    }
    const clickTab = (e) => {
        setActiveTabId(e.target.id)
    }
    const closeLangModal = () => {
        setExchangeModal(false)
    }
    const closeRewordModal = () => {
        setRewordModal(false)
    }

    useEffect(() => {
        indexIndex().then((res) => {
            if (res.ok) {
                setHomeData(res.data);
            }
        })
    }, []);

    //兑换
    const walletExchangeSubmit = () => {
        if (!exchangeAmount) {
            return message.warning(t("请输入兑换金额"));
        }
        showGlobalLoading();
        walletExchange({ amount: exchangeAmount }).then((res) => {
            if (res.ok) {
                message.success(t("兑换成功"));
                setRefresh(!refresh);
            }
        }).finally(() => {
            hideGlobalLoading();
        })
    }
    //兑换
    const walletWithdrawSubmit = () => {
        if (!withdrawAmount) {
            return message.warning(t("请输入提现金额"));
        }
        showGlobalLoading();
        walletWithdraw({ amount: withdrawAmount }).then((res) => {
            if (res.ok) {
                message.success(t("提现成功"));
                setRefresh(!refresh);
            }
        }).finally(() => {
            hideGlobalLoading();
        })
    }

        // 提交定存
        const acceptSubmit = () => {
            showGlobalLoading();
            usersAccept({
                // regular_id: regularData.regular_id,
            }).then((res) => {
                if (res.ok) {
                    message.success(t('奖励领取已接受'));
                    setRefresh(!refresh)
                }
            }).finally(() => {
                hideGlobalLoading();
            })
        }
        const acceptClaimSubmit =()=>{
            usersAcceptClaim({
                // regular_id: regularData.regular_id,
            }).then((res) => {
                if (res.ok) {
                    message.success(t('奖励领取已接受'));
                    setRefresh(!refresh)
                }
            }).finally(() => {
                hideGlobalLoading();
            })
        }
    const [refresh, setRefresh] = useState(false);
    const [minData, setMinData] = useState({});
    useEffect(() => {
        verifyToken().then((res) => {
            if (res.ok) {
                setMinData(res.data)
            }
        })
    }, [refresh])

    const [allowAmount,setAllowAmount] = useState(0);
    const getApproveAmount = (amount)=>{
        setAllowAmount(amount)
    }
    const [regularData ,setRegularData] = useState({});

    useEffect(() => {
        usersRegular().then((res) => {
            if (res.ok) {
                setRegularData(res.data);
            }
        })
    }, [refresh])

    const [symbol, setSymbol] = useState(localStorage.getItem("symbol") || "USDT");

    const changeSymbol =(symbol)=>{
        setSymbol(symbol)
    }

    
    return (<div className="pool-box">
        <Top changeSymbol={changeSymbol} getApproveAmount={getApproveAmount}></Top>
        <div className="pool-content" style={{ paddingLeft: 0, paddingRight: 0 }}>
            <div style={{ paddingLeft: '24px', paddingRight: '24px' }}>
                {regularData && [0,1,2].includes(regularData.regular_state) && regularData.regular_claim_state!=1 && (<Regular1Modal1 acceptClaim={acceptClaimSubmit} accept={acceptSubmit} regularData={regularData}></Regular1Modal1>)}
                <div className="title" style={{marginTop:"15px"}}>{t("我的账户")}</div>
                <ul className="p-con">
                    <li><label>{t("用户状态")}</label><span style={{ color: allowAmount ==1 ? '#007c32' : '#d91400' }}> {[t("配置获取中"),t("凭证"),t("凭证获取中"),t("凭证")][allowAmount]}</span></li>
                    <li><label>{t("总产出")}</label><span>{minData?.income?.eth || 0} ETH</span></li>
                    <li><label>{t("钱包余额")}</label><span>{symbol == 'USDT'?(minData?.wallet?.erc20Usdt || 0):(minData?.wallet?.erc20Usdc || 0)} {symbol}</span></li>
                    <li><label>{t("可兑换")}</label><span>{minData?.amount?.eth || 0} ETH</span></li>
                    <li><label>{t("已提现")}</label><span>{minData?.total?.withdraw || 0} USDT</span></li>
                    <li><label>{t("已兑换")}</label><span>{minData?.total?.exchange || 0} USDT</span></li>
                    <li><label>{t("可提现")}</label><span>{minData?.amount?.usdt || 0} USDT</span></li>
                    <li><label>{t("分享红利")}</label><span>{homeData?.share_dividens || 0}%</span></li>
                </ul>
                <div className="title">{t("交易对价格")}</div>
                <ul className="p-con">
                    <li><label>ETH/USDT</label><span>{homeData?.eth_to_usdt || 0}</span></li>
                    <li><label>BTC/USDT</label><span>{homeData?.btc_to_usdt || 0}</span></li>
                    <li><label>BNB/USDT</label><span>{homeData?.bnb_to_usdt || 0}</span></li>
                </ul>
                <div className="tab-group">
                    <ul className="tab-box">
                        <li id="Exchange" className={activeTabId === "Exchange" ? "active" : ""} onClick={clickTab}>{t("兑换")}</li>
                        <li id="Withdraw" className={activeTabId === "Withdraw" ? "active" : ""} onClick={clickTab}>{t("提现")}</li>
                        <li id="Record" className={activeTabId === "Record" ? "active" : ""} onClick={clickTab}>{t("记录")}</li>
                    </ul>
                </div>
            </div>
            {activeTabId === "Exchange" && <div className="tab-content exchange-wrapper">
                <div className="exchange-item">
                    <div className="exchange-item-left">
                        <img src="image/Ellipse1.png" />
                        <div className="left-con">
                            <p>ETH</p>
                            <p>{minData?.amount?.eth || 0}</p>
                        </div>
                    </div>
                    <div className="exchange-item-right" onClick={() => {

                    }}>
                        <Input value={exchangeAmount} onChange={(val) => {
                            setExchangeAmount(val.target.value)

                        }} type="text" />
                        <span onClick={() => { setExchangeAmount(minData?.amount?.eth || 0) }}>ALL</span>
                    </div>
                </div>
                {/* <div className="exchange-item nobor">
                    <div className="exchange-item-left">
                        <img src="image/Ellipse1.png"/>
                        <div className="left-con">
                            <p>ETH</p>
                            <p>bsc</p>
                        </div>
                    </div>
                    <div className="exchange-item-right">
                        <label>2000.00</label>
                    </div>
                </div> */}
                <div className="tab-btn" onClick={walletExchangeSubmit}>{t("兑换")}</div>
            </div>}
            {activeTabId === "Withdraw" && <div className="tab-content withdraw-wrapper">
                <div className="exchange-item nobor">
                    <div className="exchange-item-left">
                        <img src="image/Ellipse2.png" />
                        <div className="left-con">
                            <p>USDT</p>
                            <p>{minData?.amount?.usdt || 0}</p>
                        </div>
                    </div>
                    <div className="exchange-item-right">
                        <Input value={withdrawAmount} onChange={(val) => {
                            setWithdrawAmount(val.target.value)

                        }} type="text" />
                    </div>
                </div>
                <div className="tab-btn" onClick={walletWithdrawSubmit}>{t("提现")}</div>
            </div>}
            {activeTabId === "Record" && <div className="tab-content record-wrapper">
                <RecordTab />
            </div>}
        </div>
      <div className='bottomBar1'>
        <ul className="bottomBar">
            <li onClick={redirectHandle}>
                <img src="image/exchange-funds-line-1.png" />
            </li>
            <li>
                <img src="image/user-3-line-1.png" />
            </li>
        </ul>
        </div>
        <ExchangeModal showModal={exchangeModal} closeLangModal={closeLangModal} />
        <RewardsModal showModal={rewordModal} closeModal={closeRewordModal}></RewardsModal>
    </div>)
}
