import copy from 'copy-to-clipboard';
import {useEffect, useState} from "react";
import { useHistory ,useLocation} from 'react-router-dom'
import {message, Spin} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import LangModal from '../component/LangModal';
// import { useHistory,  } from "react-router-dom";

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 48,
        }}
        spin
    />
);
export default ()=> {
    const {t} = useTranslation();
    const history = useHistory();
    const [loading, setLoading] = useState(true)
    const [showPage, setShowPage] = useState(false);
    const copyHandle = () => {
        copy("https://"+window.location.host)
        message.success(t("复制成功"))
    }
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery()
    const inite_id = query.get('id') //2
    useEffect(() => {
        setTimeout(() => {
            // 有值跳转，没值停留本页
            if(window.ethereum){
                if(inite_id){
                    history.replace("/poolData?id="+inite_id)
                }else{
                    history.replace("/poolData");
                }
               
            } else {
                setLoading(false)
                setShowPage(true)
            }
        }, 2000)
    }, []);
    const [showModal, setShowModal] = useState(false);
    const langList = [
        { title: 'English', key: 'en', icon: "img/lang/en.png" },
        { title: 'عربي', key: 'ar', icon: "img/lang/ar.png" },
        { title: '中文', key: 'zh-CN', icon: "img/lang/zh.png" },
        { title: '繁體中文', key: 'zh-HK', icon: "img/lang/zh-TW.png" },
        { title: 'Deutsch', key: 'de', icon: "img/lang/de.png" },
        { title: 'español', key: 'es', icon: "img/lang/es.png" },
        { title: 'Français', key: 'fr', icon: "img/lang/fr.png" },
        { title: 'Indonesia', key: 'id', icon: "img/lang/id.png" },
        { title: 'Italiano', key: 'it', icon: "img/lang/it.png" },
        { title: '日本語', key: 'ja', icon: "img/lang/ja.png" },
        { title: '한국인', key: 'ko', icon: "img/lang/ko.png" },
        { title: 'Polski', key: 'pl', icon: "img/lang/pl.png" },
        { title: 'Português', key: 'pt', icon: "img/lang/pt.png" },
        { title: 'Русский', key: 'ru', icon: "img/lang/ru.png" },
        { title: 'แบบไทย', key: 'th', icon: "img/lang/th.png" },
        { title: 'Tiếng Việt', key: 'vi', icon: "img/lang/vi.png" }
    ]
    const langKey = localStorage.getItem("lang") || 'en'
    const item = langList.find(e => e.key === langKey);
    const [langImg, setLangImg] = useState(item)
    const onChangeLang = (lang) => {
        const item = langList.find(e => e.key === lang);
        setLangImg(item);

    }
    const closeLangModal = () => {
        setShowModal(false)
    }
    const clickLangModal = () => {
        setShowModal(true)
    }

    return (<>
    
            {showPage ? <div className="index">
            {showModal && <LangModal onChangeLang={onChangeLang} showModal={showModal} closeLangModal={closeLangModal} key="langModal" />}

                <div className={"h-top"}>
                    <img src="image/DeFi.png" className="logo"/>
                    <div onClick={clickLangModal} style={{ display: 'inline-flex' }}>
                        {/* <Lang /> */}
                        <div className="tbtn_wallet tbtn_wallet1">
                            <img style={{ width: '20px', marginRight: "5px" }} src={langImg.icon} />
                            {langImg.title}</div>

                    </div>
                </div>
                <img src="image/Shape1.png" className="big-img"/>
                <div className={"h-content"}>
                    <h1>{t("提醒")}</h1>
                    <p>{t("请访问钱包")}</p>
                    <p>{t("您正在访问一个去中心化网站，请在钱包应用程序中运行它")}</p>
                </div>
                <div className={"btn"} onClick={copyHandle}>{t("复制链接")}</div>
            </div> :<Spin spinning={loading}
                          tip="Loading..."
                          indicator={antIcon}
                          style={{margin: "50% auto",display: 'block', color: '#6552FE'}}>

            </Spin>}

    </>)
}
