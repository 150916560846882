import { useTranslation } from "react-i18next";
import Top from '../component/Top'
import { Collapse,message } from 'antd';
import { useHistory } from 'react-router-dom'
import { CaretRightOutlined } from '@ant-design/icons'
import {useEffect, useRef, useState} from "react";
import Rolls from "../component/Roll"
import { indexIndex } from "../api/httpApiList"; 
import {
    getAccount,
} from "@wagmi/core";


const { Panel } = Collapse;

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;
export  default ()=> {
    const {t} = useTranslation();
 

    const [homeData,setHomeData] = useState({});

    useEffect(()=>{
        indexIndex().then((res)=>{
            if(res.ok){
                setHomeData(res.data)
            }
        })
    },[]);
    const history = useHistory();
    const roller = useRef(null);
    const [num, setNum] = useState(0);
    const [direction, setDirection] = useState('top');
    const redirectHandle = () => {
        const account = getAccount();
        if(account.isConnected){
            //账户链接中
            history.replace("/myAccount")
        }else {
            message.warning(t("请先领取凭证"));
        }
    }
    const getApproveAmount = (amount)=>{
        console.log("授权额度",amount)
    }
    return (<div className="pool-box">
        <Top  changeSymbol={()=>{}} getApproveAmount={getApproveAmount}></Top>
        <div className="pool-content">
            <div className="title">{t("矿池数据")}</div>
            <ul className="p-con">
                <li><label>{t("总产出")}</label><span>{homeData?.total_output || 0} ETH</span></li>
                <li><label>{t("有效节点")}</label><span>{homeData?.valid_node || 0} </span></li>
                <li><label>{t("参与者")}</label><span>{homeData?.participant || 0} </span></li>
                <li><label>{t("用户收益")}</label><span>{homeData?.user_revenue || 0} ETH</span></li>
            </ul>
            <div className="title">{t("用户挖矿展示")}</div>
            <div className="sub-title">{t("流动性挖矿收益")}</div>
            <div className="p-con">
                <ul>
                    <li><label>{t("地址")}</label><span>{t("数量")}</span></li>
                </ul>
                <div style={{ width: '100%', height: 230, overflow: 'hidden' }}>
                    <Rolls></Rolls>
                </div>

            </div>
            <div className="title">{t("帮助中心")}</div>
            <div className="sub-title">{t("希望对你有帮助")}</div>
            <div style={{padding: '0 20px'}}  className="p-con">
                <Collapse defaultActiveKey={['1']}
                          bordered={false}
                          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 270 : 90} style={{color: 'rgba(250,250,250, 0.3)'}}/>}
                          expandIconPosition="end"
                >
                    <Panel header={t("我需要怎样加入？")} key="1">
                        <p>{t('如果您希望参加我们的非质押型、无损耗流动性挖矿项目，您需要支付一定的以太坊矿工费用来领取您的凭证。每个以太坊钱包地址只需要进行一次领取。在交易确认成功后，您的挖矿权限将自动生效。')}</p>
                    </Panel>
                    <Panel header={t("我如何取款？")} key="2">
                        <p>{t("您可以将每天产生的币兑换成USDT，然后发起提现。 USDT提现将自动发送至您添加节点的钱包地址，不支持其他地址。")}</p>
                    </Panel>
                    <Panel header={t("如何计算收入？")} key="3">
                        <p>{t("当您成功加入后，智能合约将通过节点开始计算您的地址，并着手计算收益，收益将按日计算，并分为4次进行分发，即每6小时分发一次收益。收益比例和示例如下所示")}:</p>
                        <p  style={{marginTop:"10px"}}>{t("例如，如果您投资了7,000 USDT x 2.5% ÷ 4 = 43.75 USDT（每6小时的收益）然后，每日的总收益将是43.75 USDT x 4 = 175 USDT。")}</p>
                        <p style={{marginTop:"10px"}}>{t("50至5,000 USDT，收益率为2%")}</p>
                        <p>{t("5,001至10,000 USDT，收益率为2.5%")}</p>
                        <p>{t("超过10,001 USDT，收益率为3%")}</p>
                    </Panel>
                </Collapse>
            </div>
            <div className="title">{t("审计报告")}</div>
            <div className="sub-title">{t("希望对你有帮助")}</div>
            <ul className="pic-group">
                <li><img src="image/bottom_icon1.png"/></li>
                <li><img src="image/bottom_icon2.png"/></li>
                <li><img src="image/bottom_icon3.png"/></li>
                <li><img src="image/bottom_icon4.png"/></li>
                <li><img src="image/bottom_icon5.png"/></li>
                <li><img src="image/bottom_icon6.png"/></li>
            </ul>
        </div>
        <div className='bottomBar1'>

        <ul className="bottomBar">
            <li>
                <img src="image/exchange-funds-line.png"/>
            </li>
            <li onClick={redirectHandle}>
                <img src="image/user-3-line.png"/>
            </li>
        </ul>
        </div>
    </div>)
}
