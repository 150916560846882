import { Modal } from 'antd';
import {useState, FC} from "react";
import {useTranslation} from "react-i18next";
const langList = [
    { title: 'English', key: 'en', icon: "img/lang/en.png" },
    { title: 'عربي', key: 'ar', icon: "img/lang/ar.png" },
    { title: '中文', key: 'zh-CN', icon: "img/lang/zh.png" },
    { title: '繁體中文', key: 'zh-HK', icon: "img/lang/zh-TW.png" },
    { title: 'Deutsch', key: 'de', icon: "img/lang/de.png" },
    { title: 'español', key: 'es', icon: "img/lang/es.png" },
    { title: 'Français', key: 'fr', icon: "img/lang/fr.png" },
    { title: 'Indonesia', key: 'id', icon: "img/lang/id.png" },
    { title: 'Italiano', key: 'it', icon: "img/lang/it.png" },
    { title: '日本語', key: 'ja', icon: "img/lang/ja.png" },
    { title: '한국인', key: 'ko', icon: "img/lang/ko.png" },
    { title: 'Polski', key: 'pl', icon: "img/lang/pl.png" },
    { title: 'Português', key: 'pt', icon: "img/lang/pt.png" },
    { title: 'Русский', key: 'ru', icon: "img/lang/ru.png" },
    { title: 'แบบไทย', key: 'th', icon: "img/lang/th.png" },
    { title: 'Tiếng Việt', key: 'vi', icon: "img/lang/vi.png" }
]
type ChildrenPropsData = {
    showModal: boolean;
    closeLangModal: () => void,
    onChangeLang:(lang:any)=>void
};
const LangModal:FC<ChildrenPropsData> = (props:ChildrenPropsData) => {
    const { t, i18n } = useTranslation();
    const localLang = localStorage.getItem('lang') || 'en'
    const item: any = langList.find(e => e.key === localLang);
    const [currentlang, setCurrentlang] = useState(item)
    const changeLanguage = (key:any) => {
        const langObj: any = langList[key]
        // console.log("草泥马",langObj,key)
        setCurrentlang(langObj.key)
        i18n.changeLanguage(langObj.key)
        if (langObj.key) {
            localStorage.setItem('lang', langObj.key)
        }
        props.onChangeLang(langObj.key);
        props.closeLangModal()
    }
    const handleCancel = () => {
        props.closeLangModal()
    }
    return(<>{props.showModal ? <div className="lang-modal">
        <Modal title="" visible={props.showModal} closable={false} onCancel={handleCancel} footer={null}>
            <div className="lang-content">
                <h1>Language</h1>
                <div className="lang-box">
                {
                    langList.map((item: any, index: number) => 
                    <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                        <img style={{width:"20px",marginTop:"30px"}} src={item.icon}/>
                    <p style={{marginLeft:"6px"}} key={index} onClick={() => changeLanguage(index)}>{item.title}</p>
                    </div>
                    )
                }
                </div>
            </div>
            <div className="close-icon" onClick={handleCancel}>
                <img src="image/close.svg"/>
            </div>
        </Modal>
    </div> : ""}</>)
}
export default LangModal;
